import Sign_in from '@/components/Auth/Sign_in'
import { check_discourse_payload, discourse_redirect } from '@/utils/discourse_auth'
import { auth_cookie_name, default_site_url, global_auth_cookie_name } from 'constant/url'
import { getCookie, getCookies } from 'cookies-next'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getUserDetails } from './api/api'
import { decryptDataWithAES, getAuthKey } from 'constant/functions'
import {
  check_tradly_auth_integration_payload,
  tradly_integration_redirect,
} from '@/utils/tradly_integration_auth'

// import Header from "../partials/Header";

function SignIn({ isLoggedIn, isEmailVerified }) {
  const router = useRouter()
  const redirect_url = router.query.to || default_site_url
  const sso = router?.query?.sso || router?.query?.payload
  const sig = router.query.sig
  const identifier = router?.query?.identity

  const [is_log_in_by_discourse, setISlogInByDiscourse] = useState(false)
  const [is_log_in_by_tradly_integration, setISlogInByTradlyIntegration] = useState(false)

  useEffect(() => {
    if (!isLoggedIn && sso && sig && identifier === 'tradly') {
      setISlogInByTradlyIntegration(true)
    } else if (!isLoggedIn && sso && sig && (identifier !== 'tradly' || identifier === undefined)) {
      setISlogInByDiscourse(true)
    } else if (isLoggedIn && sso && sig) {
      const auth_key = getAuthKey()
      if (identifier === undefined) {
        getUserDetails(auth_key)
          .then((res) => {
            if (res?.user) {
              discourse_redirect({ sso, sig, user: res?.user, router })
            } else {
              router.push(redirect_url)
            }
          })
          .catch((error) => {
            router.push(redirect_url)
          })
      } else if (identifier === 'tradly') {
        getUserDetails(auth_key)
          .then((res) => {
            if (res?.user) {
              tradly_integration_redirect({ sso, sig, user: res?.user, router })
            } else {
              router.push(redirect_url)
            }
          })
          .catch((error) => {
            router.push(redirect_url)
          })
      }
    } else if (isLoggedIn && !isEmailVerified) {
      router.push(`/verify_email?to=${redirect_url}`)
    } else if (isLoggedIn && isEmailVerified) {
      router.push(redirect_url)
    }
  }, [router])

  return (
    <>
      <Head>
        <meta
          key="twitter:title"
          name="twitter:title"
          content="Tradly – Bring your Dream into Reality. API-FIRST COMMERCE."
        />
        <meta
          key="og:title"
          property="og:title"
          content="Tradly – Bring your Dream into Reality. API-FIRST COMMERCE."
        />
        <title>Tradly - Signin</title>
      </Head>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <main className="flex-grow">
          {is_log_in_by_discourse ? (
            check_discourse_payload({
              component: (
                <Sign_in sso={sso} sig={sig} is_log_in_by_discourse={is_log_in_by_discourse} />
              ),
              sso: sso,
              sig: sig,
            })
          ) : is_log_in_by_tradly_integration ? (
            check_tradly_auth_integration_payload({
              component: (
                <Sign_in
                  sso={sso}
                  sig={sig}
                  is_log_in_by_tradly_integration={is_log_in_by_tradly_integration}
                />
              ),
              sso: sso,
              sig: sig,
            })
          ) : (
            <Sign_in sso={sso} sig={sig} isLoggedIn={isLoggedIn} />
          )}
        </main>
      </div>
    </>
  )
}

export default SignIn

export async function getServerSideProps({ req, res }) {
  // Pass data to the page via props
  const user_data = getCookie(global_auth_cookie_name, { req, res })
    ? JSON.parse(decryptDataWithAES(getCookie(global_auth_cookie_name, { req, res })))
    : null
  const isLoggedIn = user_data ? true : getCookie(auth_cookie_name, { req, res }) ? true : false
  const isEmailVerified = user_data ? user_data?.email_verified : false

  return { props: { isLoggedIn, isEmailVerified } }
}
